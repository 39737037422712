import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button } from "reactstrap";

const BatteryIndicator = () => {
  const [batteryLevel, setBatteryLevel] = useState(0); // Assume initial state is 0 for demo purposes
  const [isAnimating, setIsAnimating] = useState(false); // Track if we are animating to 100%
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    // Simulating fetching of the initial battery state
    navigator.getBattery().then(function(battery) {
      setBatteryLevel(battery.level);
    });
  }, []);

  // Function to simulate charging and show the message
  const handleButtonClick = () => {
    setIsAnimating(true); // Begin the animation
    setShowMessage(false); // Hide the message during animation

    // After the animation completes, set the battery level to 100%
    setTimeout(() => {
      setBatteryLevel(1); // Simulate 100% charge
      setIsAnimating(false); // End the animation
      setShowMessage(true); // Show the message
    }, 1500); // Adjust time as per your animation duration
  };

  // Determine the battery level class based on the current level
  const batteryLevelClass = () => {
    if (batteryLevel < 0.2) return 'low';
    else if (batteryLevel < 0.5) return 'medium';
    else return 'high'; // Battery level of 50% or more is considered 'high'
  };

  return (
    <Container className="mt-5 battery-container">
      <Row className="justify-content-center">
        <Col md={4} className="text-center battery-col">
          <div className={`battery-indicator ${batteryLevelClass()}`}>
            <div
              className="battery-level"
              style={{ width: `${batteryLevel * 100}%` }}
            >
              <span className="battery-text">
                {isAnimating ? 'Charging...' : `${Math.round(batteryLevel * 100)}%`}
              </span>
            </div>
          </div>
          <Button className="btn btn-danger-gradiant mt-20 btn-arrow" onClick={handleButtonClick}>
            Let's charge your battery <i className="fa fa-bolt"></i>
          </Button>
          {showMessage && (
            <div className="love-message mt-3">
              I love you so so much and I'm sorry for hurting you, my baby. You mean the world to me, and I wish I made this birthday more special. I'm so happy for having a rare gem like you.
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default BatteryIndicator;
