/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';

import img1 from '../../../assets/images/features/feature13/img1.jpg';
import img2 from '../../../assets/images/features/feature13/img2.jpg';
import img3 from '../../../assets/images/features/feature13/img3.jpg';
import img4 from '../../../assets/images/features/feature13/img4.jpg';
import img5 from '../../../assets/images/features/feature30/img1.jpg';

const FeatureComponent = () => {
    return (
        <div>
            <div className="spacer bg-light">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">You mean to me....</h1>
                            <h6 className="subtitle"></h6>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="spacer feature4">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <span className="label label-danger label-rounded">THE WORLD</span>
                            <h2 className="title">YOU ARE MY WORLD</h2>
                            <h6 className="subtitle">WHEN I'M WITH YOU I DON'T THINK ABOUT ANYTHING ELSE I FORGET ALL THE WORLD</h6>
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                        <Col md="6" className="wrap-feature4-box">
                            <Card>
                                <CardBody>
                                    <div className="icon-round bg-light-info"><i className="fa fa-heart"></i></div>
                                    <h5 className="font-medium">My love</h5>
                                    <p className="m-t-20">Through sadness and happiness, hardships and good days. WE WERE TOGETHER. we shared so many memories and we wil be sharing many more, i'm praying to get married as soon as possible BABY.</p>
                                    <a href="#" className="linking text-themecolor">happy birthday <i className="ti-arrow-right"></i></a>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" className="wrap-feature4-box">
                            <Card>
                                <CardBody>
                                    <div className="icon-round bg-light-info"><i className="fa fa-stethoscope"></i></div>
                                    <h5 className="font-medium">My wife who makes me proud</h5>
                                    <p className="m-t-20">I'm so proud of what you achieve, i was so happy when you reached your dream and i will always be prouf of you. May ALLAH give you more success in your way and let me see you reaching your goal, i hope i will be there, i will be so happy seeing my baby girl finaly becoming a DOCTOR and IM SURE you will be one of the best in the planet.</p>
                                    <a className="linking text-themecolor" href="#">happy birthday <i className="ti-arrow-right"></i></a>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="spacer ">
                <Container className="feature30">
                    <Row>
                        <Col lg="10"><img src={img5} className="rounded img-responsive" alt="wrappixel" /></Col>
                        <Col lg="5" md="7" className="text-center wrap-feature30-box">
                            <Card className="card-shadow">
                                <CardBody>
                                    <div className="p-20">
                                        <span className="label label-info label-rounded">My partner</span>
                                        <h3 className="title">You were always there for me</h3>
                                        <p></p>
                                        <a className="btn btn-info-gradiant btn-md btn-arrow m-t-20" href="#"><span>happy birthday<i className="ti-arrow-right"></i></span></a>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default FeatureComponent;
