import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import img5 from '../../assets/images/logos/birthday.png';

const CallToAction = () => {
    return (
                <div>
                    <div className="spacer ">
                    <Container className="feature30">
                        <Row>
                        <Col className="text-center">

                            <Col lg="10"><img src={img5} className="rounded img-responsive" alt="birthday" id="birthday" /></Col>                        </Col>

                            <Col lg="5" md="7" className="text-center wrap-feature30-box">
                                <Card className="card-shadow">
                                    <CardBody>
                                        <div className="p-20">
                                            <span className="label label-danger label-rounded">BABY</span>
                                            <h3 className="title">HAPPY BIRTHDAY <i class="fa-solid fa-cake-candles"></i></h3>
                                            <p></p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>

    );  
}

export default CallToAction;
