/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';

import img1 from '../../../assets/images/testimonial/1.jpg';
import img2 from '../../../assets/images/testimonial/2.jpg';
import img3 from '../../../assets/images/testimonial/3.jpg';

const TestimonialComponent = () => {
    return (
        <div>
            <div className="spacer">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Our Love is Endless...</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="testimonial3 spacer bg-light">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                        </Col>
                    </Row>
                    <Row className="testi3 m-t-40 justify-content-center">

                        <Col lg="4" md="6">
                            <Card className="card-shadow">
                                <CardBody>
                                    <h6 className="font-light m-b-30">I love you so freaking much</h6>
                                    <div className="d-flex no-block align-items-center">
                                        <span className="thumb-img"><img src={img1} alt="wrapkit" className="circle" /></span>
                                        <div className="m-l-20">
                                            <h6 className="m-b-0 customer">Your love</h6>

                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card className="card-shadow">
                                <CardBody>
                                    <h6 className="font-light m-b-30">Can't wait to marry you babe, happy birthday my lovely wife</h6>
                                    <div className="d-flex no-block align-items-center">
                                        <span className="thumb-img"><img src={img2} alt="wrapkit" className="circle" /></span>
                                        <div className="m-l-20">
                                            <h6 className="m-b-0 customer">Your Husband</h6>

                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default TestimonialComponent;
