import React, { useEffect } from "react";
import "./assets/scss/style.scss";
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  Routes,
  Route,
  HashRouter,
  Navigate,
  useNavigate
} from "react-router-dom";
import Components from "./views/components/components.jsx";
import CustomComponents from "./views/custom-components/custom-components.jsx";

const root = ReactDOM.createRoot(document.getElementById('root'));

const currentDate = new Date();
const redirectDate = new Date('2028-02-29T00:00:00');

const App = () => {
  let navigate = useNavigate();

  useEffect(() => {
    // Redirect if the current date is before 29 February 2024
    if (currentDate < redirectDate) {
      navigate('/components');
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<CustomComponents />} />
      <Route path="/components" element={<Components />} />
    </Routes>
  );
};

root.render(
  <HashRouter>
    <App />
  </HashRouter>
);

reportWebVitals();
